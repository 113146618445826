import {Row, Container, Col} from 'react-bootstrap';
import { MOBILE_WIDTH } from '../../../help/constants';

export default function Herotitleparagraph({data}){

  //console.log( 'Herotitleparagraph data:', data );
  const {
    device_visibility,
    paragraph,
    section_id,
    titlecontent
  } = data;

  if(device_visibility==='desktop' && window.innerWidth<=MOBILE_WIDTH){
    return null;
  }
  if(device_visibility==='mobile' && window.innerWidth>MOBILE_WIDTH){
    return null;
  }

  return <section id={section_id} className="hero-title-paragraph">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="htp-content">
            <div className="the-content" dangerouslySetInnerHTML={{ __html: titlecontent }} />
            <div className="the-paragraph" dangerouslySetInnerHTML={{ __html: paragraph }} />
            <a href="#scroll-down" className="btn-arrow-down" aria-label="Scroll Down"></a>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
}