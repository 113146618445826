import {Row, Container, Col} from 'react-bootstrap';
import { MOBILE_WIDTH } from '../../../help/constants';

export default function Agencypartners({data}){

  //console.log('Agencypartners data:', data);

  const {
    section_id,
    paragraph,
    paragraph_mobile,
    top_left_label,
    partners
  } = data;

  return <section id={section_id} className="agency-partners">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="content-left our-clients-content-left animate-bottom-top-fade-in">
            <div className="our-work-label">{top_left_label!==''?top_left_label:'Agency Partners'}</div>
            <p className="our-work-paragraph" dangerouslySetInnerHTML={{ __html: window.innerWidth>MOBILE_WIDTH?paragraph:paragraph_mobile }} />
          </div>
          <div className="agency-partners-list">
            {
              partners.map((partner, key)=>{
                return <div className="agency-partner-item animate-bottom-top-fade-in" key={`agency-partner-item-${key}`} style={{transitionDelay:`${200*key}ms`}}>
                  <img src={partner.icon} alt={partner.alt} />
                </div>
              })
            }
          </div>
        </Col>
      </Row>
    </Container>
  </section>

}