import {Row, Container, Col} from 'react-bootstrap';
import VideoOptimized from '../video-optimized/Index';
import { useEffect } from 'react';
import { MOBILE_WIDTH } from '../../../help/constants';

export default function Ourwork({data}){

  console.log('Our work data:', data);

  const {
    section_id,
    our_work_label,
    paragraph,
    paragraph_for_mobile,
    button_arrow_down_link,
    boxes_links,
    view_all_project_label,
    view_all_project_link,
    hide_heading,
    show_just_boxes,
    type
  } = data;

  const {
    isSmoothScrolling,
    post,
  } = window.wordpress_data;


  const __ourWorkMediaContent = (mediaDetails, media_index) => {
    if(mediaDetails.media_type==='video'){
      return <VideoOptimized id={`${section_id}-media-${media_index}`} playWhenInFocus={true} muted loop className="media" url={mediaDetails.video} />;
      
    }
    else if(mediaDetails.media_type==='photo'){
      return <img src={mediaDetails.photo} alt={post.post_title} />
    }
    return null;
  }


  
  const __OnScroll = function(){
    

  }

  const ___BTNViewAllProjects = ()=>{
    return <a href={view_all_project_link!==''?view_all_project_link:'#view-all-projects-link-is-missing'} className="btn-view-all-projects">
      {view_all_project_label!==''?view_all_project_label:'View All Projects'}
    </a>;
  }


  useEffect(()=>{
    
    
    if(isSmoothScrolling===true){
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
    else{
      window.addEventListener('scroll', __OnScroll);
    }

    return ()=>{

      window.removeEventListener('scroll', __OnScroll);
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
  }, []);

  return <section id={section_id} className={`our-work ${type}`}>
    <Container>
      <Row>
        <Col lg={12}>
          {
            hide_heading===true || show_just_boxes===true
            ?
            null
            :
            <div className="our-work-header animate-bottom-top-fade-in">
              <div className="left-box">
                <p className="our-work-label">{our_work_label!==''?our_work_label:'Our Work'}</p>
                <p className="our-work-paragraph" dangerouslySetInnerHTML={{ __html: window.innerWidth>MOBILE_WIDTH || paragraph_for_mobile===''?paragraph:paragraph_for_mobile }} />
              </div>
              {
                type==='x4-boxes-in-a-row'
                ?
                ___BTNViewAllProjects()
                :
                <div className="right-box animate-bottom-top-fade-in" style={{transitionDelay:'200ms'}}>
                  <a href={button_arrow_down_link!==''?button_arrow_down_link:'#scroll-down'} className="btn-arrow-down" aria-label="Scroll Down"></a>
                </div>
              }
            </div>
          }
          <div className="our-work-boxes">
            {
              boxes_links.map((box_item, key)=>{
                return <a 
                  id={`${section_id}-item-${key}`}
                  href={box_item.link!==''?box_item.link:'#box-link-is-missing'} 
                  className="our-work-box-item animate-bottom-top-fade-in" 
                  key={`our-work-box-item-key-${key}`}
                  style={{transitionDelay:`${key*200}ms`}}>
                  <div className="hero-media our-work-hero-media">
                    {__ourWorkMediaContent(box_item, key)}
                  </div>
                  <div className="our-work-item-content">
                    <strong>{box_item.title}</strong>
                    <p>{box_item.paragraph}</p>
                  </div>
                </a>
              })
            }
          </div>

          {
            show_just_boxes===true || type==='x4-boxes-in-a-row'?
            null 
            :
            <div id={`${section_id}-our-work-footer`} className="our-work-footer animate-bottom-top-fade-in">
              {___BTNViewAllProjects()}
            </div>
          }
          

        </Col>
      </Row>
    </Container>
  </section>
}