import {Row, Container, Col} from 'react-bootstrap';
import { MOBILE_WIDTH } from '../../../help/constants';

export default function Justcontent({data}){
  console.log('Justcontent:', data);

  const {
    content,
    content__mobile,
    add_padding_on_the_right,
    device_visibility
  } = data;

  
  if(device_visibility==='mobile' && window.innerWidth>MOBILE_WIDTH){return null;}
  else if(device_visibility==='desktop' && window.innerWidth<=MOBILE_WIDTH){return null;}

  return <section className={`just-content ${add_padding_on_the_right===true?'just-content-desktop-padding-right':''}`}>
    <Container>
      <Row>
        <Col>
          <div className="just-content-holder" dangerouslySetInnerHTML={{ __html: (
            content__mobile===undefined || content__mobile==='' || window.innerWidth>MOBILE_WIDTH
            ?
            content
            :
            content__mobile
            ) }} />
        </Col>
      </Row>
    </Container>
  </section>
}