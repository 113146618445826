import { useEffect } from 'react';
import {Row, Container, Col} from 'react-bootstrap';

export default function Footer({data}){

  //console.log('data:', data)

  const {
    nav_menu_items_footer, nav_menu_item_social, options, title,
    isAnimated,
    isSmoothScrolling,
    THEME_URL,
  } = window.wordpress_data;
  /*const {
    header_logo, label_near_logo, footer_copyright, office_email_address,
    bank_details, bank_details_hebrew,

    footer_paragraph, footer_paragraph_mobile, footer_slogan, phone_number, email_address, social_icons, 
  } = options;*/
  /*const {
    copyright_text,
    email,
    phone,
    subscribe_link,
    title,
  } = website_settings;*/
  const {
    copyright_text,
    email,
    phone,
    subscribe_link,
  } = options;

  /*let footer_special_top__minus_margin = '';
  if(post.fields!==undefined && post.fields.page_settings!==undefined)
    footer_special_top__minus_margin = post.fields.page_settings.footer_special_top__minus_margin;*/

  /*function _bank_details_english(){
    const bank_parts = bank_details.split('<br />')
    console.log('bank_parts:', bank_parts)
    let bank_details_html = `<strong>${bank_parts[0]}</strong>`
    for(let i=1;i<bank_parts.length;i++)
      bank_details_html = `${bank_details_html}<br/>${bank_parts[i]}`
    return bank_details_html;
  }*/

  //console.log('social_icons:', social_icons)

  
  const __OnScroll = ()=>{
    
    if(isAnimated){
      const footer_the_logo = document.getElementById('footer-the-logo');
      const footer_the_logoB = footer_the_logo.getBoundingClientRect();
      if(!footer_the_logo.classList.contains('animate-to-the-end') && (footer_the_logoB.top<=window.innerHeight && footer_the_logoB.top>=0)){
        footer_the_logo.classList.add('animate-to-the-end');
      }
    }

  }
  
  useEffect(()=>{
    
    
    if(isSmoothScrolling===true){
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
    else{
      window.addEventListener('scroll', __OnScroll);
    }

    return ()=>{

      window.removeEventListener('scroll', __OnScroll);
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
  }, []);


  return <footer id="footer">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="footer-content">
            <div className="left-part animate-bottom-top-fade-in">
              <a href="#LetsWork" className="btn-lets-work">Let’s Work</a>
              <div>
                <a href={`mailto:${email}`} className="btn-footer-email">{email}</a>
              </div>
              <div className="footer-contact-buttons">
                <a href={`tel:${phone}`} className="btn-footer-phone">{phone}</a>
                <a href={`${subscribe_link}`} className="btn-footer-phone">SUBSCRIBE</a>
              </div>
            </div>
            <div className="right-part">
              <ul className="footer-menu animate-bottom-top-fade-in" style={{transitionDelay:'200ms'}}>
                <li>
                  <a href="#site">Site</a>
                </li>
                {
                  nav_menu_items_footer.map((item, key)=>{
                    return <li key={`menu-item-key-${key}`}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  })
                }
              </ul>
              <ul className="footer-menu animate-bottom-top-fade-in" style={{transitionDelay:'400ms'}}>
                <li>
                  <a href="#Social">Social</a>
                </li>
                {
                  nav_menu_item_social.map((item, key)=>{
                    return <li key={`menu-item-key-${key}`}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  })
                }
              </ul>
              <p className="copyright animate-bottom-top-fade-in" style={{transitionDelay:'600ms'}}>{copyright_text}</p>
            </div>
          </div>

          <div id={`footer-the-logo`} className="footer-big-logo">
            <img src={`${THEME_URL}assets/images/footer-banner.svg`} alt={title} />
            <img src={`${THEME_URL}assets/images/footer-logo-mobile.svg`} alt={title} className="d-none" />
          </div>

        </Col>
      </Row>
    </Container>
  </footer>
}