import { useEffect, useRef } from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import { MOBILE_WIDTH } from '../../../help/constants';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Ourclients({data}){

  //console.log('our clients data:', data);

  const {
    section_id,

    button_partner_with_us_custom_link,
    button_partner_with_us_label,
    button_partner_with_us_pagepost_link,
    paragraph,
    paragraph_mobile,
    top_left_label,

    the_logos,

  } = data;

  

  //let swiper = null;
  const swiper = useRef();
  let intervalNextIndex = -1;
  let indexSlide = 1;
  let indexSlidePlus = 1;

  const animateToNextSlide = ()=>{
    clearTimeout(intervalNextIndex)
    intervalNextIndex = setTimeout(()=>{
      if(swiper!==null)
        swiper.current.swiper.slideTo(indexSlide+=indexSlidePlus)
      animateToNextSlide();
    }, 4000)
  }

  
  useEffect(()=>{


    /*swiper = new Swiper(`#${section_id}-swiper`, {
      slidesPerView: "auto",
      spaceBetween: 0,
      loop:true,
      speed: 4000,
    });
    swiper.on('slideChange', function(){
      if(swiper.isEnd){
        indexSlidePlus = -1;
      }
      else if(swiper.isBeginning){
        indexSlidePlus = 1;
      }
    })

    animateToNextSlide()
    swiper.slideTo(indexSlide+=indexSlidePlus)

    return ()=>{
      clearTimeout(intervalNextIndex)
    }*/

    
    animateToNextSlide()
    swiper.current.swiper.slideTo(indexSlide+=indexSlidePlus);
    return ()=>{
      clearTimeout(intervalNextIndex)
    }

  }, []);

  return <section id={section_id} className="our-clients">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="our-clients-top-content animate-bottom-top-fade-in">
            <div className="content-left our-clients-content-left">
              <div className="our-work-label">{top_left_label!==''?top_left_label:'Our Clients'}</div>
              <p className="our-work-paragraph" dangerouslySetInnerHTML={{ __html: window.innerWidth>MOBILE_WIDTH?paragraph:paragraph_mobile }} />
            </div>
            <div className="content-right">
              <a href={button_partner_with_us_custom_link!==''?button_partner_with_us_custom_link:button_partner_with_us_pagepost_link} className="btn-view-all-projects">
                {
                  button_partner_with_us_label!==''
                  ?
                  button_partner_with_us_label
                  :
                  'Partner With Us'
                }
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>



    <div className="our-clients-logos animate-bottom-top-fade-in">
      
      {
        /*
        <div id={`${section_id}-swiper`} className="swiper">
          <div className="swiper-wrapper">
            {
              the_logos.map((logo, key)=>{
                return <div className="swiper-slide" key={`our-clients-logo-slide-${key}`} style={{width: `calc(2*var(--padding-client-logo-x)+${logo.width/10}*var(--delta))`}}>
                  <div className="our-clients-logo">
                    <img src={logo.logo} alt={logo.alt} />
                  </div>
                </div>
              })
            }
          </div>
        </div>
        */
      }
      <Swiper 
        ref={swiper}
        id={`${section_id}-swiper`}
        spaceBetween="0"
        slidesPerView="auto"
        loop={true}
        speed={4000}
        onSlideChange={()=>{
          //console.log('Slider changing');

          if(swiper.current.swiper.isEnd){
            indexSlidePlus = -1;
          }
          else if(swiper.current.swiper.isBeginning){
            indexSlidePlus = 1;
          }

        }}
        >
      {
        the_logos.map((logo, key)=>{
          return <SwiperSlide key={`our-clients-logo-slide-${key}`} style={{width: `calc(2*var(--padding-client-logo-x)+${logo.width/10}*var(--delta))`}}>
            <div className="our-clients-logo">
              <img src={logo.logo} alt={logo.alt} />
            </div>
          </SwiperSlide>
        })
      }
      </Swiper>


    </div>

  </section>
}