import {Row, Container, Col, Button} from 'react-bootstrap';
import ButtonJoinUsAndDonate from '../btnjoinusanddonate/Index';
import ZVideo from '../video/Index';
import { useEffect } from 'react';

export default function Content({data}){

  console.log('content data:', data.type, data)

  const {
    type, 
    left_content,
    left_content_mobile,
    right_content,
    brown_title,
    group_content_video,
    paragraph_aligned_center,
    paragraph_aligned_center_mobile,
    padding_bottom,
    the_president_content,
    about_big_title,
    about_small_title,
    about_content__photo,
    donate_content,
    contact_us_content,
    contact_address_html,
    contact_contact_html,
    contact_form_html,
    media_content,
    background_photo,
  } = data;

  let media_gallery_template_index = 0;

  const __colContent = ()=>{
    if(type==="content-content"){
      return <div className="html-content-content">
        <div className="left-content desktop" dangerouslySetInnerHTML={{ __html: left_content }} />
        <div className="left-content mobile d-none" dangerouslySetInnerHTML={{ __html: left_content_mobile!==undefined && left_content_mobile!==''?left_content_mobile:left_content }} />
        <div className="right-content" dangerouslySetInnerHTML={{ __html: right_content }} />
      </div>
    }
    else if(type==="brown-title"){
      return <div className="html-brown-title">
        <h3>{brown_title}</h3>
      </div>
    }
    else if(type==="content-video"){
      return <div className="html-content-video">
        <div className="left-content">
          <div dangerouslySetInnerHTML={{ __html: group_content_video.left_content.content }} ></div>
          {
            group_content_video.left_content.add_donate_botton===true?
            <ButtonJoinUsAndDonate color="outline-warning" size="small" />
            :
            null
          }
        </div>
        
        <ZVideo mp4_url={group_content_video.right_content.video_mp4} />

      </div>
    }
    else if(type==="paragraph-aligned-center"){
      return <p >
        <span className="desktop" dangerouslySetInnerHTML={{ __html: paragraph_aligned_center }} />
        <span className="mobile d-none" dangerouslySetInnerHTML={{ __html: paragraph_aligned_center_mobile!==undefined && paragraph_aligned_center_mobile!==''?paragraph_aligned_center_mobile:paragraph_aligned_center }} />
      </p>
    }
    else if(type==="the-president-say"){
      return <div className="html-content-president-holder">
        <div className="content-right mobile d-none" dangerouslySetInnerHTML={{ __html: the_president_content.right_content }} />
        <div className="content-left">
          <img src={the_president_content.left_content.profile_photo} alt="The President" />
          <p dangerouslySetInnerHTML={{ __html: the_president_content.left_content.profile_paragraph }} />
        </div>
        <div className="content-right desktop" dangerouslySetInnerHTML={{ __html: the_president_content.right_content }} />
      </div>
    }
    else if(type==="about-content-photo"){
      return <div className="html-about-content-photo">
        <div className="html-about-full-width-content">
          {about_big_title!==undefined && about_big_title!==''?<h2>{about_big_title}</h2>:null}
          {about_small_title!==undefined && about_small_title!==''?<h4>{about_small_title}</h4>:null}
        </div>
        <div className="left-content" dangerouslySetInnerHTML={{ __html: about_content__photo.content }} />
        <div className="right-content">
          <img src={about_content__photo.photo} alt="About Us" />
        </div>
      </div>
    }
    else if(type==="content-donate"){
      return <div className="content-donate">
        <h2 dangerouslySetInnerHTML={{ __html: donate_content.title }} />
        <ul className="content-donate-items">
          {
            donate_content.items.map((item, key)=>{
              let button_link = '';
              if(item.outside_link!=='')button_link=item.outside_link;
              else if(item.button_link!=='')button_link=item.button_link;
              return <li key={`item-${key}`}>
                <Button variant="outline-warning" className="btn-donate-item" href={button_link} onClick={(e)=>{
                  if(button_link==='')e.preventDefault();
                }}>{item.button_title}</Button>

                <span dangerouslySetInnerHTML={{ __html: item.paragraph }} />

              </li>
            })
          }
        </ul>
      </div>
    }
    else if(type==="contact-us-content"){
      return <div className="html-contact-content">
        <div className="full-width-content">
          <h2>{contact_us_content.left_content.title}</h2>
        </div>
        <div className="left-content">
          <div className="contact-form-holder" dangerouslySetInnerHTML={{ __html: contact_form_html }} />
        </div>
        <div className="right-content">
          <div className="google-map-holder">
            <div id="contact-google-map"></div>
          </div>
          <div className="contact-x2-column-details">
            <div className="left-column" dangerouslySetInnerHTML={{ __html: contact_address_html }} />
            <div className="right-column" dangerouslySetInnerHTML={{ __html: contact_contact_html }} />
          </div>
        </div>
      </div>
    }
    else if(type==="media-content"){
      return <div className="html-media-content">
        <h2>{media_content.title}</h2>
        <div className="media-gallery">
          {media_content.photos.map((photo, key)=>{
            const media_gallery_template = [
              'w-1-3 mr', 'w-3-3', 
              'w-1-3 mr', 'w-1-3 mr', 'w-1-3',
              'w-3-3 mr', 'w-1-3'
            ];
            const media_gallery_item_size = media_gallery_template[media_gallery_template_index];
            media_gallery_template_index++;
            if(media_gallery_template_index>=media_gallery_template.length)media_gallery_template_index=0;
            return <div className={`media-gallery-item ${media_gallery_item_size}`} key={`media-gallery-item-${key}`}>
              <img src={photo.photo} alt={media_content.title} />
            </div>
          })}
        </div>
      </div>
    }

    return <div>Not content defined</div>
  }

  const _background = ()=>{
    if(type==="background-title" || type==="have-background"){
      return <div className="background" style={{
        backgroundImage: `url('${background_photo}')`
      }}></div>
    }
    return null;
  }

  const __SetGoogleMap = ()=>{
    console.log(document.getElementById('contact-google-map'))
    const map = new window.google.maps.Map(document.getElementById('contact-google-map'), {
      center: { lat: contact_us_content.right_content.google_map.lat, lng: contact_us_content.right_content.google_map.lng },
      zoom: contact_us_content.right_content.google_map.zoom
    });
  }

  useEffect(()=>{
    if(type==="contact-us-content"){
      __SetGoogleMap();
      if(window.location.href.indexOf('#')!==-1){
        const y = document.getElementById(window.location.href.split('#')[1]).getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y,
          behavior: 'instant'
        });
      }
    }
    return ()=>{}
  }, [])

  return <section className={`content ${type} ${padding_bottom} `}>

    {_background()}

    <Container>
      <Row>
        <Col lg={12}>
        {__colContent()}
        </Col>
      </Row>
    </Container>
  </section>
}