import {Row, Container, Col} from 'react-bootstrap';

export default function Blocks({data}){

  const {items} = data;

  console.log('data:', data)

  return <section className={`blocks`}>
    <Container>
      <Row>
        <Col lg={12}>
          <div className="blocks-grid">
          {
            items.map((item, key)=>{
              const {page_or_post} = item;
              const {fields} = page_or_post;
              return <a className="block-item" key={`block-item-${key}`} href={page_or_post.url}>
                <div className="block-item-thumbnail">
                  <img src={fields.thumbnail} alt={page_or_post.post_title} />
                </div>
                <div className="block-item-content">
                  <h4 dangerouslySetInnerHTML={{ __html: page_or_post.post_title }} />
                  <div className="block-item-short-description" dangerouslySetInnerHTML={{ __html: fields.short_description }} />
                  <h5 className="block-item-motivation-quotes" dangerouslySetInnerHTML={{ __html: fields.motivation_quote }} />
                </div>
              </a>
            })
          }
          </div>
        </Col>
      </Row>
    </Container>
  </section>
}