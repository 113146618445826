import {Row, Container, Col} from 'react-bootstrap';

export default function Photophotos({data}){
  console.log('data photophotos:', data);

  const {
    type, single_photo, x2_photos, it_is_link, single_photo_link,
    mobile_bottom_margin
  } = data;
  const {
    photo_1, 
    photo_1_it_is_link,
    photo_1_link,

    photo_2,
    photo_2_it_is_link,
    photo_2_link
  } = x2_photos;

  const {
    post,
    THEME_URL
  } = window.wordpress_data;

  const {post_title} = post;
  const no_photo = `${THEME_URL}assets/images/image-placeholder-500x500.jpg`;
  const photoIsValid = (photoLink)=>{
    return (photoLink!=='' && photoLink!==undefined && photoLink!==false);
  }

  const _content = () => {
    if(type==='single-photo'){
      if(it_is_link===true)
        return <a href={single_photo_link}><img src={photoIsValid(single_photo)?single_photo:no_photo} alt={post_title} /></a>
      return <img src={photoIsValid(single_photo)?single_photo:no_photo} alt={post_title} />
    }
    else if(type==='x2-photos'){
      return <div className="photos-x2">
        <div className={`${!photoIsValid(photo_1) || !photoIsValid(photo_2)?'mb-0':''}`}>
          {
            photoIsValid(photo_1)?
            (
              photo_1_it_is_link===true
              ?
              <a href={photo_1_link}><img src={photo_1} alt={post_title} /></a>
              :
              <img src={photo_1} alt={post_title} />
            )
            :
            null
          }
        </div>
        <div>
          {
            photoIsValid(photo_2)?
            (
              photo_2_it_is_link===true
              ?
              <a href={photo_2_link}><img src={photo_2} alt={post_title} /></a>
              :
              <img src={photo_2} alt={post_title} />
            )
            :
            null
          }
        </div>
      </div>
    }
    return null
  }

  return <section className={`photo-photos ${type} ${mobile_bottom_margin!==undefined && mobile_bottom_margin!==false?mobile_bottom_margin:''}`}>
    <Container>
      <Row>
        <Col lg={12}>
          {
            _content()
          }
        </Col>
      </Row>
    </Container>
  </section>
}