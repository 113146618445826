import { useRef, useState } from "react"

export default function ZVideo({mp4_url}){



  const videoRef = useRef(null)
  const [isPlaying, set_isPlaying] = useState(false)

  return <div className={`z-video ${isPlaying?'z-video-is-playing':''}`}>
    <video ref={videoRef} onEnded={()=>{
      set_isPlaying(false)
    }}>
      <source 
        src={mp4_url} type="video/mp4"/>
    </video>

    <div className="z-video-play-pause" onClick={()=>{
      console.log('videoRef:', videoRef)
      if(isPlaying){
        //videoRef.current.pause()
      }
      else{
        //videoRef.current.play()
      }
      set_isPlaying(!isPlaying)
    }}></div>
  </div>
}