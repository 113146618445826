import {useEffect, useState} from 'react';

import {Row, Container, Col} from 'react-bootstrap';
import { isPreloader } from '../../help/help';

export default function Header({data}){

  console.log('Header window.wordpress_data', window.wordpress_data);

  const [animate_to_the_end, setAnimateToTheEnd] = useState(false);

  const {
    THEME_URL,
    nav_menu_items, nav_menu_items_right, header_id, home_url,
    title,
    post,

    //isPreloader(),
    isAnimated,

  } = window.wordpress_data;

  const logo_dark=`${THEME_URL}assets/images/logo-with-point.svg`;
  const logo_mobile=`${THEME_URL}assets/images/logo-mobile.svg`;
  const logo_mobile_light=`${THEME_URL}assets/images/logo-light.svg`;
  const logo_light = `${THEME_URL}assets/images/need-to-be-set`;

  //console.log('header data:', data);

  //const {logo_dark, logo_mobile, logo_mobile_light, nav_menu_items, nav_menu_items_right, header_id, home_url} = data;
  const {fields} = post;
  //console.log(data, 'nav_menu_items:', nav_menu_items)
  //console.log(website_settings.language)
  const isDark = ()=>{
    return (fields!==undefined && fields.page_settings!==undefined && fields.page_settings.header_colors_type==='header-dark');
  }

  const [hamburgLabel, setHamburgLabel] = useState('Menu')
  

  const _menu_content = ()=>{
    return <ul className="header-main-menu">
    {nav_menu_items.map((menu_item, key_item)=>{
      return <li key={`menu-item-${key_item}`}>
        <a href={menu_item.url} className={`${menu_item.object_id===post.ID.toString()?'active':''}`}>{menu_item.title}</a>
      </li>
    })}
    </ul>
  }
  const _menu_content_2 = () => {
    console.log('nav_menu_items_right:', nav_menu_items_right);
    return <ul className="header-menu-underline">
    {nav_menu_items_right.map((menu_item, key_item)=>{
      return <li key={`menu-item-${key_item}`}>
        <a href={menu_item.url} target={`${menu_item.target!==undefined?menu_item.target:'_self'}`} className={`${menu_item.object_id===post.ID.toString()?'active':''}`}>{menu_item.title}</a>
      </li>
    })}
    </ul>
  }

  useEffect(()=>{
    
    if(isPreloader()===true && isAnimated){
      setTimeout(()=>{
        setAnimateToTheEnd(true);
      }, 2500);
    }
    else if(isAnimated===true){
      setAnimateToTheEnd(true);
    }
  }, []);

  return <header className={`${header_id} ${fields!==undefined && fields.page_settings!==undefined?fields.page_settings.header_colors_type:''} ${animate_to_the_end===true?'animate-to-the-end':''}`}>
    <Container>
      <Row>
        <Col lg={12}>
          <div className="header-content">
            <div className="header-left-part">
              <a href={home_url} className={`header-main-link`}>
                <img src={isDark()?logo_light:logo_dark} alt={title} />
                <img src={logo_mobile} alt={title} className="d-none" />
                <img src={logo_mobile_light} alt={title} className="d-none" />
              </a>
            </div>

            <div className="header-right-part">
              {_menu_content()}
              {_menu_content_2()}
              <a href="#hamburg-button" className="btn-hamburg" onClick={(e)=>{
                e.preventDefault()
                if(document.body.classList.contains('mobile-menu-visible')){
                  document.body.classList.remove('mobile-menu-visible');
                  setHamburgLabel('Menu')
                }
                else{
                  document.body.classList.add('mobile-menu-visible');
                  setHamburgLabel('Close')
                }
              }}>
                {hamburgLabel}
              </a>
            </div>

            <a href="#btn-hamburg" onClick={(e)=>{
              e.preventDefault();
              if(document.body.classList.contains('mm-opened')){
                document.body.classList.remove('mm-opened');
              }
              else{
                document.body.classList.add('mm-opened');
              }
            }}>{''}</a>

            

          </div>
        </Col>
      </Row>
    </Container>
  </header>
}