import {Row, Container, Col} from 'react-bootstrap';
import { MOBILE_WIDTH } from '../../../help/constants';


export default function Paragraphslist({data}){

  //console.log('paragraphlist data:', data);

  const {
    section_id,
    items
  } = data;

  return <section id={section_id} className="paragraphs-list">
    <Container>
      <Row>
        <Col>
          {
            items.map((p, key)=>{
              return <p className="animate-bottom-top-fade-in" key={`paragraph-item-${key}`} dangerouslySetInnerHTML={{ __html: window.innerWidth>MOBILE_WIDTH?p.paragraph:p.paragraph_mobile }} />
            })
          }
        </Col>
      </Row>
    </Container>
  </section>
}