export default function UserRecord({user_record, type}){

  console.log('user_record:', user_record)

  if(user_record===undefined)return <div>[Error, User Record must be valid post record.]</div>;


  const {
    record_profile_photo,
    record_short_description,
    record_user_name,
  } = user_record.fields;


  if(type==='user-role')
    return <div className={`user-record user-record-role`}>
      <div className="profile-photo">
        <img src={user_record.fields.profile_photo} alt={`${user_record.user.data.display_name}`} />
      </div>
      <div className="user-record-role-content">
        <h4>{user_record.user.data.display_name}</h4>
        <h5>-{user_record.user_custom_position!==undefined && user_record.user_custom_position!==''?user_record.user_custom_position:user_record.fields.position}</h5>
        {user_record.fields.place!==undefined && user_record.fields.place!==''?<p>{user_record.fields.place}</p>:null}
      </div>
    </div>

  return <div className={`user-record ${type==='small-for-grid'?'user-record-small-for-grid':''}`}>
    <div className="user-record-profile">
      <img src={record_profile_photo} alt={record_user_name} />
      <h5 className="text-center">{record_user_name}</h5>
    </div>
    <div className={`user-record-content`}>
      <h3>{user_record.post_title}</h3>
      <div className={`record-short-description`} dangerouslySetInnerHTML={{ __html: record_short_description }} />
    </div>
  </div>
}