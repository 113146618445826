import {Row, Container, Col} from 'react-bootstrap';
import VideoOptimized from '../video-optimized/Index';
import { MOBILE_WIDTH } from '../../../help/constants';

export default function Gallery({data}){

  console.log('Gallery: data', data);

  const {
    section_id,
    gallery_rows,
    device_visibility
  } = data;

  const {
    post
  } = window.wordpress_data;
  

  const ___Media = (media)=>{
    const {type, photo, video} = media;

    if(type==='video'){
      return <VideoOptimized autoPlay={true} muted loop className="media" url={video} />;
    }
    return <img src={photo} alt={post.post_title} className="media" />;
  }

  const ___RowMedia = (item_row)=>{
    const {
      items_per_row,
      media,
      media_2
    } = item_row;

    if(items_per_row==='one-per-row'){
      return <div className="media-row">{___Media(media)}</div>
    }
    else if(items_per_row==='two-per-row'){
      return <div className="media-row">{___Media(media)}{___Media(media_2)}</div>
    }
    return null;
  }


  if(device_visibility==='mobile' && window.innerWidth>MOBILE_WIDTH){return null;}
  else if(device_visibility==='desktop' && window.innerWidth<=MOBILE_WIDTH){return null;}

  return <section className="gallery">
    <Container>
      <Row>
        <Col lg={12}>
          {
            gallery_rows.map((row_data, key)=>{
              return <div className={`gallery-row ${row_data.items_per_row}`} key={`gallery-row-${key}`}>{
                ___RowMedia(row_data)
              }</div>
            })
          }
        </Col>
      </Row>
    </Container>
  </section>

}