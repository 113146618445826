//import { useState } from 'react';
import { useEffect } from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import VideoOptimized from '../video-optimized/Index';


export default function Theblackphone({data}){
  //console.log('Theblackphone data:', data);

  
  /*const [bigVideStyle, set_bigVideStyle] = useState({
    left:'0px',
    top:'0px',
    width: '100%',
    height: '100%',
  });*/
  //const [hideBigVideo, set_hideTheBigVideo] = useState(false);

  const {
    section_id,
    media,
    list,
  } = data;
  
  const {
    isSmoothScrolling
  } = window.wordpress_data;

  /*const bigVideo = () => {
    return document.getElementById(`${section_id}-big-video`);
  }
  const smallVideo = () => {
    return document.getElementById(`${section_id}-video`);
  }*/
  const phone = () => {
    return document.getElementById(`${section_id}-phone`);
  }


  const s = ()=>{return document.getElementById(section_id);}
  const sBounding = ()=>{return s().getBoundingClientRect();}
  
  const __OnScroll = (e) => {
    
    if(sBounding().top<.2*window.innerHeight && !s().classList.contains('is-animated')){
      s().classList.add('is-animated');
      /*set_bigVideStyle({
        left:`${phone().offsetLeft + smallVideo().offsetLeft}px`,
        top:`${phone().offsetTop + smallVideo().offsetTop}px`,
        width: `${smallVideo().clientWidth}px`,
        height: `${smallVideo().clientHeight}px`,
        //opacity: 0,
      });*/
      //bigVideo().classList.add('d-none');

      phone().classList.add('normal-scale');

      setTimeout(()=>{
        //set_hideTheBigVideo(true)
        phone().classList.add('move-to-final-position')
        list.map((item, key)=>{
          item = document.getElementById(`${section_id}-item-${key}`);
          setTimeout(()=>{
            item.style.transform = `translateY(0px)`;
            item.classList.add('visible')
            //console.log('item:', item);
          }, 300+200*key)
        })
      }, 1*1000)
    }

    /*const b = s().getBoundingClientRect();
    const videos = s().getElementsByTagName('video');*/
    

  }

  
  useEffect(()=>{
    
    if(isSmoothScrolling===true){
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
    else{
      window.addEventListener('scroll', __OnScroll);
    }

    return ()=>{
      document.getElementById('website-content').removeEventListener('scroll', __OnScroll);
      window.removeEventListener('scroll', __OnScroll);
    }
  }, []);


  const __renderMedia = (index) => {
    if(media.indexOf('.mp4')!==-1 || media.indexOf('.MP4')!==-1 || media.indexOf('.mov')!==-1 || media.indexOf('.MOV')!==-1){
      return <VideoOptimized id={`${section_id}-video-player-${index}`} muted loop className="media" url={media} />;
    }
    else{
      return <img src={media} alt={postMessage.post_title} className="media" />
    }
  }

  return <section id={section_id} className="black-phone">
    <Container className="container-black-phone">
      <Row>
        <Col lg={12}>
          <div className="black-phone-content">
            <div id={`${section_id}-phone`} className="black-phone-holder">
              <div id={`${section_id}-video`} className="hero-media black-phone-media">
                {__renderMedia(1)}
              </div>
            </div>
            <ul className="black-phone-list">
              {
                list.map((item, key)=>{
                  return <li id={`${section_id}-item-${key}`} key={`black-phone-item-${key}`}>
                    <h4>{item.value}</h4>
                    <p>{item.label}</p>
                  </li>
                })
              }
            </ul>
          </div>
        </Col>
      </Row>
    </Container>

    {
      /*hideBigVideo===true
      ?
      null
      :
      <div id={`${section_id}-big-video`} className="hero-media black-phone-media-big" style={bigVideStyle}>
        {__renderMedia(2)}
      </div>*/
    }
  </section>
}