import { useEffect } from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import { MOBILE_WIDTH } from '../../../help/constants';

export default function Titleparagraph({data}){
  
  const {section_id, content, device_visibility} = data;

  
  


  /*const __OnScroll = function(){
    const section = document.getElementById(section_id);
    const sBounding = section.getBoundingClientRect();
    if(!section.classList.contains('animate-to-the-end') && isAnimated && (sBounding.top<=window.innerHeight && sBounding.top>=0)){
      section.classList.add('animate-to-the-end');
    }
  }*/


  useEffect(()=>{
    
    
    /*if(isSmoothScrolling===true){
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
    else{
      window.addEventListener('scroll', __OnScroll);
    }*/

    return ()=>{

      /*window.removeEventListener('scroll', __OnScroll);
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);*/
    }
  }, []);


  
  if(device_visibility==='mobile' && window.innerWidth>MOBILE_WIDTH){return null;}
  if(device_visibility==='desktop' && window.innerWidth<=MOBILE_WIDTH){return null;}


  return <section id={section_id} className="title-paragraph animate-bottom-top-fade-in">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="title-paragraph-content" dangerouslySetInnerHTML={{ __html: content }} />
        </Col>
      </Row>
    </Container>
  </section>
}