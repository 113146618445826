import {Row, Container, Col} from 'react-bootstrap';

export default function Ourteam({data}){

  //console.log('Our team data:', data)

  const {
    section_id,
    lets_chat_custom_link,
    lets_chat_label,
    lets_chat_postpage_link,
    paragraph,
    the_team,
    top_left_label,
  } = data;

  return <section id={section_id} className="our-team">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="content-left our-clients-content-left animate-bottom-top-fade-in">
            <div className="our-work-label">{top_left_label!==''?top_left_label:'Our Team'}</div>
            <p className="our-work-paragraph" dangerouslySetInnerHTML={{ __html: paragraph }} />
          </div>
          <div className="our-team-list">
            {
              the_team.map((person, key)=>{
                return <div className="our-team-list-item animate-bottom-top-fade-in" key={`our-team-list-item-${key}`} style={{transitionDelay:`${200*key}ms`}}>
                  <h4>{person.name}</h4>
                  <p>{person.job_position}</p>
                  <img src={person.photo} alt={person.name} />
                </div>
              })
            }
          </div>

          <a href={lets_chat_custom_link!==''?lets_chat_custom_link:lets_chat_postpage_link} className="btn-view-all-projects animate-bottom-top-fade-in">
            {lets_chat_label!==''?lets_chat_label:'Let’s Chat!'}
          </a>

        </Col>
      </Row>
    </Container>
  </section>
}