import {Row, Container, Col} from 'react-bootstrap';
import ButtonJoinUsAndDonate from '../btnjoinusanddonate/Index';

export default function Heading({data}){

  const {
    left_content,
    right_content,
    background_photo,
    type,
  } = data;

  console.log('data:', data)

  const __background_element = ()=>{

    if(type==="home-page"){
      return <div className="background" style={{backgroundImage:`url('${background_photo}')`}}></div>
    }
    else if(type==="radio-page" || type==='coach-page')
      return <div className="background" style={{backgroundImage:`url('${background_photo}')`}}></div>
    return <div className="background"></div>
  }
  
  const _content = ()=>{
    if(type==="home-page"){
      return <div className="container-flex">
        <div className="left-content" dangerouslySetInnerHTML={{ __html: left_content }} />
        <div className="right-content">
          <div>
            <p dangerouslySetInnerHTML={{ __html: right_content.paragraph }} />
            {
              right_content.join_us_button===true
              ?
              <ButtonJoinUsAndDonate color={right_content.join_us_button_style!==undefined?right_content.join_us_button_style:''} />
              :
              null
            }
          </div>
        </div>
      </div>
    }
    return 'Undefined content for heading';
  }
  
  return <section className={`heading ${type!==undefined?type:''}`}>

    {__background_element()}

    {/*<Container>
      <Row>
        <Col lg={12}>
          <div className="content">
            {paragraph!==undefined && paragraph!==''?<p>{paragraph}</p>:null}
            <h1>{title}</h1>
            {
              checks_list!=='' ?
              <ul className="heading-checks">
                {
                  checks_list.split("\n").map((text, key)=>{
                    return <li key={`list-item-key-${key}`}>
                      <i className="fa-solid fa-check"></i> {text}
                    </li>
                  })
                }
              </ul>
              :
              null
            }
            {bottom_title!==undefined && bottom_title!==''?<h3>{bottom_title}</h3>:null}
          </div>
        </Col>
      </Row>
    </Container>*/}

    <Container>
      <Row>
        <Col lg={12}>
          {_content()}
        </Col>
      </Row>
    </Container>
  </section>
}