import { useEffect, useState } from "react";
import { 
  //getCookie, 
  //isPreloader, 
  setCookie, 
} from "../../help/help";

export default function Preloader(){

  
  const THEME_URL = ()=>{
    return (window.wordpress_data!==undefined?window.wordpress_data.THEME_URL:'');
  }


  const [animation_classes, set_animation_classes] = useState('');

  /*const _DOMContentLoaded = () => {
    set_animation_classes(`${animation_classes} show-the-label`)
  }*/

  const all_photos = [
    `${THEME_URL()}/assets/images/preloader/socialystic.svg`,
    `${THEME_URL()}/assets/images/preloader/point.svg`,
    /*`${THEME_URL()}/assets/images/preloader/1.jpg`,
    `${THEME_URL()}/assets/images/preloader/2.jpg`,
    `${THEME_URL()}/assets/images/preloader/3.jpg`,
    `${THEME_URL()}/assets/images/preloader/4.jpg`,
    `${THEME_URL()}/assets/images/preloader/5.jpg`,
    `${THEME_URL()}/assets/images/preloader/6.jpg`,
    `${THEME_URL()}/assets/images/preloader/7.jpg`,*/
  ];

  const photosJPGS = [
    /*{url:`${THEME_URL()}/assets/images/preloader/1.jpg`},
    {url:`${THEME_URL()}/assets/images/preloader/2.jpg`},
    {url:`${THEME_URL()}/assets/images/preloader/3.jpg`},
    {url:`${THEME_URL()}/assets/images/preloader/4.jpg`},
    {url:`${THEME_URL()}/assets/images/preloader/5.jpg`},
    {url:`${THEME_URL()}/assets/images/preloader/6.jpg`},
    {url:`${THEME_URL()}/assets/images/preloader/7.jpg`},*/
  ];

  let countPreloading = 0;
  let intervalAddingPreloader = -1;
  let indexPhotoForZooming = 0;



  const __StartTheAnimation = ()=>{
    //if(isPreloader()!==true)return;
    setTimeout(()=>{
      set_animation_classes(`${animation_classes} show-the-label`);
      setTimeout(()=>{
        clearInterval(intervalAddingPreloader);
        /*intervalAddingPreloader = setInterval(()=>{
          var imgT = document.getElementById(`preloader-photo-jpg-${indexPhotoForZooming}`);
          imgT.classList.add('zoomed')
          indexPhotoForZooming++;
          if(photosJPGS[indexPhotoForZooming]===undefined){
            clearInterval(intervalAddingPreloader)
          }
        }, 100);*/
      }, 200);
    }, 200);

    setTimeout(()=>{
      //return;
      for(indexPhotoForZooming=0;indexPhotoForZooming<photosJPGS.length;indexPhotoForZooming++){
        var imHiddingAddNewClass = document.getElementById(`preloader-photo-jpg-${indexPhotoForZooming}`);
        imHiddingAddNewClass.classList.add('short-animated');
      }
      indexPhotoForZooming=photosJPGS.length-1;
      intervalAddingPreloader = setInterval(()=>{
        var imgTHidding = document.getElementById(`preloader-photo-jpg-${indexPhotoForZooming}`);
        if(imgTHidding===null || imgTHidding===undefined){
          clearInterval(intervalAddingPreloader);
        }
        else{
          imgTHidding.classList.remove('zoomed');
          indexPhotoForZooming--;
        }
        //console.log('AAAAAAA');
        document.getElementById('preloader').classList.add('hide');

        setCookie('preloaderOnceLoaded', true);
      }, 20);



    }, 2500);
  }



  useEffect(()=>{

    //document.addEventListener("DOMContentLoaded", _DOMContentLoaded);
    /*all_photos.map((url)=>{
      var img=new Image();
      img.src=url;
      img.onload = ()=>{
        console.log('loaded')
        countPreloading++;
        if(countPreloading===all_photos.length){
          console.log('loading finished');
          __StartTheAnimation();
          setCookie('preloaderOnceLoaded', true);
        }
      };
    });*/
    __StartTheAnimation();

    return ()=>{
      //document.removeEventListener("DOMContentLoaded", _DOMContentLoaded);
      clearInterval(intervalAddingPreloader)
    }
  }, []);

  //if(isPreloader()!==true)return null;


  return <section id="preloader" className={`preloader ${animation_classes}`}>


    {
      photosJPGS.map((photo, key)=>{
        return <img id={`preloader-photo-jpg-${key}`} className="preloader-photo" src={photo.url} key={`photo-jpg-${key}`} style={{
          ...photo.css, 
          position:'absolute',
        }} alt="Preloader" />
      })
    }
    <div className="label-with-point" style={{position:'relative'}}>
      <div className="label">
        <img src={all_photos[0]} alt="Preloader" />
      </div>
      <img className="point" src={all_photos[1]} alt="Preloader Point" />
    </div>

  </section>
}