/** 
 * This function can view posts and users too
*/
import {Row, Container, Col} from 'react-bootstrap';
import UserRecord from '../userrecord/Index';

export default function Gridofposts({data}){

  console.log('data:', data)
  const {posts, posts_x3_in_row, sections_background} = data;

  const __content = ()=>{

    if(data.type==='users-list'){
      return <div>
        {
          data.users_x2_in_row.map((x2_users, key)=>{
            return <section className={`x2-users-in-a-row ${key%2===0?'bg-light':''} ${sections_background} `} key={`user-set-x2-users-${key}`}>
              <Container>
                <Row>
                  <Col lg={12}>
                  {
                    x2_users.map((user, key_user)=>{
                      return <UserRecord user_record={user} key={`user-record-${key}-${key_user}`} type="user-role" />
                    })
                  }
                  </Col>
                </Row>
              </Container>
            </section>
          })
        }
      </div>
    }
    else if(data['category_honorable-mention']===true || data.category_record===true || data['category_record-main']===true){
      return <div>
      {
        posts_x3_in_row.map((array_x3_posts, key)=>{
          return <section className={`grid-of-posts-x3-rows ${key%2===0?'bg-light':''} ${sections_background} `} key={`section-${key}`}>
            <Container>
              <Row>
                <Col lg={12}>
                {
                  array_x3_posts.map((post, key_post)=>{
                    return <UserRecord user_record={post} key={`user-record-${key}-${key_post}`} type="small-for-grid" />
                  })
                }
                </Col>
              </Row>
            </Container>
          </section>
        })
      }
      </div>
    }
    return <div className="text-center">No frontend set for the posts</div>
  }

  return <section className="grid-of-posts">
  {__content()}
  </section>
}