import {Row, Container, Col} from 'react-bootstrap';

export default function Logos({data}){

  const {logos_items} = data;

  console.log('data:', data)

  return <section className="logos">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="logos-holder">
            {
              logos_items.map((logo_item, key)=>{
                return <div className="logo-item" key={`logo-item-${key}`}>
                  <img src={logo_item.logo__grey} alt={logo_item.alt} />
                  <img src={logo_item.logo_over} alt={logo_item.alt} />
                </div>
              })
            }
          </div>
        </Col>
      </Row>
    </Container>
  </section>
}