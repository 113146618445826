import {Row, Container, Col} from 'react-bootstrap';
import VideoOptimized from '../video-optimized/Index';

export default function Media({data}){


  console.log('Media data:', data);

  const {
    photo,
    section_id,
    type,
    video
  } = data;

  const {post} = window.wordpress_data;

  
  const __renderMedia = ()=>{
    if(type==='video'){
      return <VideoOptimized autoPlay={true} muted loop className="media" url={video} />;
    }
    return <img src={photo} alt={post.post_title} className="media" />;
  }


  return <section className="s-media">

    <Container>
      <Row>
        <Col>
          <div className="hero-media">
            {__renderMedia()}
          </div>
        </Col>
      </Row>
    </Container>

  </section>
}