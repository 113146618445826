//import logo from './logo.svg';
//import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import { useEffect, useState } from 'react';

import Header from "./website-parts/header/Index";
import Footer from './website-parts/footer/Index';
import Hero from './website-parts/components/hero/Index';
import Titleparagraph from './website-parts/components/titleparagraph/Index';
import Agencypartners from './website-parts/components/agencypartners/Index';
import Blocks from './website-parts/components/blocks/Index';
import Content from './website-parts/components/content/Index';
import Gallery from './website-parts/components/gallery/Index';
import Gridofposts from './website-parts/components/gridofposts/Index';
import Heading from './website-parts/components/heading/Index';
import Herotitleparagraph from './website-parts/components/herotitleparagraph/Index';
import Justcontent from './website-parts/components/justcontent/Index';
import Logos from './website-parts/components/logos/Index';
import Media from './website-parts/components/media/Index';
import Ourclients from './website-parts/components/ourclients/Index';
import Ourservices from './website-parts/components/ourservices/Index';
import Ourteam from './website-parts/components/ourteam/Index';
import Ourwork from './website-parts/components/ourwork/Index';
import Paragraphslist from './website-parts/components/paragraphslist/Index';
import Photophotos from './website-parts/components/photophotos/Index';
import Scrollinglabel from './website-parts/components/scrollinglabel/Index';
import Theblackphone from './website-parts/components/theblackphone/Index';
import Work from './website-parts/components/work/Index';
import Slidergallerymedia from './website-parts/components/slidergallerymedia/Index';
import Preloader from './website-parts/preloader/Index';
import { getCookie, isPreloader } from './help/help';



import './assest/css/style.css';
import './assest/css/style-responsive.css';

function App() {


  const [wordpressDataIsLoaded, setwordpressDataIsLoaded] = useState(false);

  /*const {
    isSmoothScrolling,
    isAnimated,
    sections
  } = (window.wordpress_data!==undefined?window.wordpress_data:{});
  console.log('window.wordpress_data:', window.wordpress_data, isAnimated);*/
  const isSmoothScrolling = false;
  const isAnimated = ()=>{
    return (window.wordpress_data!==undefined?window.wordpress_data.isAnimated:false);
  }
  const sections = ()=>{
    return (window.wordpress_data!==undefined?window.wordpress_data.sections:[]);
  }


  const sectionsF = ()=>{
    
    
    

    return {
      'hero':Hero,
      'titleparagraph':Titleparagraph,
      'agencypartners':Agencypartners,
      'blocks':Blocks,
      'content':Content,
      'gallery':Gallery,
      'gridofposts':Gridofposts,
      'heading':Heading,
      'herotitleparagraph':Herotitleparagraph,
      'justcontent':Justcontent,
      'logos':Logos,
      'media':Media,
      'ourclients':Ourclients,
      'ourservices':Ourservices,
      'ourteam':Ourteam,
      'ourwork':Ourwork,
      'paragraphslist':Paragraphslist,
      'photophotos':Photophotos,
      'scrollinglabel':Scrollinglabel,
      'theblackphone':Theblackphone,
      'work':Work,
      'slidergallerymedia':Slidergallerymedia,

    };
  }


  const _wheel = ()=>{}
  const __OnZlatkoScroll = ()=>{
    if(isAnimated()===true){
      const list_elements_for_animation = document.getElementsByClassName('animate-bottom-top-fade-in');
      let el_index;
      for(el_index=0;el_index<list_elements_for_animation.length;el_index++){
        const el = list_elements_for_animation[el_index];
        const elB = el.getBoundingClientRect();
        if(!el.classList.contains('animate-to-the-end') && (elB.top<=window.innerHeight && elB.top>=0)){
          el.classList.add('animate-to-the-end');
        }
      }
    }
  }
  const __OnWindowScroll = ()=>{
    var scrollingElement = document.scrollingElement || document.documentElement;
    //console.log('e:', e, scrollingElement.scrollTop)
    //if(scrollingElement.scrollTop<(5/10)*window.screen.height){
    if(scrollingElement.scrollTop===0){
      document.body.classList.remove('scrolled');
    }
    else{
      document.body.classList.add('scrolled');
    }
  }

  const LoadCalendly = ()=>{
    setTimeout(()=>{
      if(document.getElementsByClassName('calendly-inline-widget').length>0){
        /*
        example text that should be added:
        <!-- Calendly inline widget begin --><div class="calendly-inline-widget" data-url="https://calendly.com/socialystic/30-minute-discovery-call-clone?hide_event_type_details=1" style="min-width:320px;height:700px;"></div><!-- Calendly inline widget end -->
        */
        console.log('Loading calendly');
        const head = document.querySelector('head');const script = document.createElement('script');script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');head.appendChild(script);
      }
    }, 1)
  }

  useEffect(()=>{
    if(window.wordpress_data===undefined){
      fetch(`${window.HOME_URL!==undefined?window.HOME_URL:'http://localhost/projects/max-bishop/wordpress'}/wp-admin/admin-post.php?action=LoadDataForReact&PAGE_ID=${window.PAGE_ID!==undefined?window.PAGE_ID:/*example post:229*//*home:*/12}`)
        .then(response => response.json())
        .then(wordpress_data => {
          console.log('wordpress_data:', wordpress_data);
          window.wordpress_data = wordpress_data;
          setwordpressDataIsLoaded(true);

          //in localhost react it load here
          LoadCalendly();

        })
        .catch(error => {
        });
    }
    else{
      setwordpressDataIsLoaded(true);
    }


      window.addEventListener('scroll', __OnWindowScroll);

      //window.scrollTo(0, document.body.scrollHeight); console.log('it is scrolling to bottom for debugging');
      if(window.location.href.indexOf('#')!==-1){
        var el = document.getElementById(window.location.href.split('#')[1]);
        if(el!==undefined && el!==null)
          window.scrollTo(0, el.offsetTop);
      }

      
      /*if(isSmoothScrolling===true){
        document.getElementById('website-content').addEventListener('scroll', __OnZlatkoScroll);
      }
      else{
        window.addEventListener('scroll', __OnZlatkoScroll);
      }*/
      window.addEventListener('scroll', __OnZlatkoScroll);

      //in wordpress code it load here
      LoadCalendly();


      

      return ()=>{
        window.removeEventListener('wheel', _wheel);
        window.removeEventListener('scroll', __OnWindowScroll);


        window.removeEventListener('scroll', __OnZlatkoScroll);
        //document.getElementById('website-content').removeEventListener('scroll', __OnZlatkoScroll);
      }
    
  }, []);



  if(!wordpressDataIsLoaded)return null;

  


  return (
    <div id="website-content" className={`website-content ${isSmoothScrolling?'smooth-scroll':''} ${isAnimated()?'is-animated':''}`}>
      <div id="website-content-inner" className="website-content-inner">
        <Header data={{header_id:'static'}} />
        {
          sections().map((data, key)=>{
            /*if(data.acf_fc_layout==='hero')return <Hero key={`section-${key}`} data={data} />;
            if(data.acf_fc_layout==="titleparagraph")return <Titleparagraph key={`section-${key}`} data={data} />;*/
            console.log('data.acf_fc_layout:', data.acf_fc_layout);
            const Sectiontype = sectionsF()[data.acf_fc_layout];
            return <Sectiontype key={`section-${key}`} data={data} />;
            
          })
        }
        <Footer data={{}} />
        {
          //window.wordpress_data===undefined?'undefined data':`we have data, we have preloader ${window.wordpress_data.isPreloader}`
        }
        {
          //getCookie('preloaderOnceLoaded')===''?'no cookie':'we have cookie'
        }
        {
          isPreloader()
          ?
          <Preloader />
          :
          null
        }
        

      </div>
    </div>
  );
}

export default App;
