import { useEffect, useState } from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import VideoOptimized from '../video-optimized/Index';

export default function Ourservices({data}){
  //console.log('data:', data)

  const {
    section_id,
    our_services_label,
    right_media,
    menu_items,
    type,
    services_items
  } = data;

  const {
    isSmoothScrolling
  } = window.wordpress_data;

  console.log('services_items:', services_items);
  const [mediaUrl, setMediaUrl] = useState(false);
  const [rightListParagraphs, setRightListParagraphs] = useState([]);

  /**
   * when the type of the services is items, it will working by selecting items and showing
   * media/video 
   * or list of paragraphs
   * so actually on roll over the left list contentTypeActive can change if the next roll overed is 
   * different type then previews
   * it will have values media or html
   */
  const CONTENT_ACTIVE_TYPE_MEDIA = 'media';
  const CONTENT_ACTIVE_TYPE_HTML = 'html';
  const [contentTypeActive, setContentTypeActive] = useState(null);
  /*let mediaUrl = false;
  const setMediaUrl = (m)=>{
    mediaUrl = m;
    __renderMedia();
  }*/

  const video = ()=>{
    const s = document.getElementById(section_id);
    const v = s.getElementsByTagName('video');
    if(v.length===1)
      return v[0];
    return null;
  }


  const ChangeContent = (content, key)=>{
    console.log('content:', content);
    if(content.type!==contentTypeActive){
      setContentTypeActive(content.type);
    }
    if(content.type===CONTENT_ACTIVE_TYPE_HTML){
      ChangeHTML(content.list.split("\r\n"), key);
    }
    else{
      ChangeMedia(content.media, key);
    }
  }

  const ChangeMedia = (m, index)=>{
    if(m===mediaUrl)return;
    setMediaUrl(m);
    /*if(video()!==null){
      video().load();
      video().play();
    }*/
    setTimeout(()=>{
      if(video()!==null){
        video().load();
      }
    }, 1);
  }
  const ChangeHTML = (list, key)=>{
    console.log('list, key:', list, key);
    //setRightListParagraphs([]);
    setRightListParagraphs(list);

    
    setTimeout(()=>{
      const list_texts = document.getElementById(section_id).getElementsByClassName('list-item-text');
      for(let ilt=0;ilt<list_texts.length;ilt++){
        list_texts[ilt].classList.add('animate-to-the-end');
      }
    }, 100);
  }

  const RightContent = ()=>{
    if(contentTypeActive===CONTENT_ACTIVE_TYPE_MEDIA){
      return <div className="hero-media our-services-media ">
        {__renderMedia()}
      </div>;
    }
    else if(contentTypeActive===CONTENT_ACTIVE_TYPE_HTML){
      /*for(let ilt=0;ilt<list_texts.length;ilt++){
        list_texts[ilt].classList.remove('animate-to-the-end');
        list_texts[ilt].classList.remove('animate-bottom-top-fade-in');
      }*/
      return <div className="hero-media our-services-media is-list" style={{
        //transitionDelay:'200ms'
        }}>
        <ul>
        {
          rightListParagraphs.map((text, textKey)=>{
            return <li key={`list-right-item-${textKey}-${Math.round(Math.random()*1000000)}`} className="list-item-text animate-bottom-top-fade-in ">{text}</li>
          })
        }
        </ul>
      </div>;
    }
    return null;
  }

  const __renderMedia = () => {


    let _mediaURL = right_media;
    if(type==='items' && mediaUrl===false){
      _mediaURL = services_items[0].media;
      //console.log('menu_itemsmenu_itemsmenu_items:', menu_items);
    }
    else if(mediaUrl!==false){
      _mediaURL = mediaUrl;
    }
    console.log('_mediaURL:', _mediaURL)

    if(_mediaURL.indexOf('.mp4')!==-1 || right_media.indexOf('.MP4')!==-1 || _mediaURL.indexOf('.mov')!==-1 || _mediaURL.indexOf('.MOV')!==-1){
      if(_mediaURL!==false)
        return <VideoOptimized id={`${section_id}-video`} autoPlay={true} muted loop className="media" url={_mediaURL} preload="auto" />;
      return <VideoOptimized id={`${section_id}-video`} playWhenInFocus={true} muted loop className="media" url={_mediaURL} />;
    }
    else{
      return <img src={_mediaURL} alt={our_services_label!==''?our_services_label:'Our Services'} className="media" />
    }
  }

  const __ServicesMenu = (deviceType)=>{
    const menuId = (deviceType==='mobile'?`${section_id}-menu-m`:`${section_id}-menu-d`);
    const menuClass = `our-services-menu ${deviceType==='mobile'?'d-none':''}`;
    if(type==='items')
      return <ul className={menuClass} id={menuId}>
      {
        services_items.map((mi, key)=>{

          return <li key={`menu-item-${key}`} id={`${section_id}-link-${key}-${deviceType==='mobile'?'mobile':'desktop'}`} className="animate-bottom-top-fade-in" style={{transitionDelay:`${200*key}ms`}}>
            <a 
              href={`#service-${key}`} 
              onClick={(e)=>{
                e.preventDefault();
                //if(video()!==null)video().pause();
                ChangeContent(mi, key);
                /*console.log('mi:', mi);
                ChangeMedia(mi.media, key);*/
              }}
              onMouseEnter={()=>{
                //console.log('enter');
                //if(video()!==null)video().pause();
                ChangeContent(mi, key);
                //ChangeMedia(mi.media, key);
              }}
            >{mi.title}</a>
          </li>
        })
      }
      </ul>;
    else 
      return <ul className={menuClass} id={menuId}>
      {
        menu_items.map((mi, key)=>{
          return <li key={`menu-item-${key}`} id={`${section_id}-link-${key}-desktop`} className="animate-bottom-top-fade-in" style={{transitionDelay:`${200*key}ms`}}>
            <a href={mi.url}>{mi.title}</a>
          </li>
        })
      }
      </ul>;
  }


  const __OnScroll = ()=>{
  }

  
  useEffect(()=>{
    
    
    if(type==='items' && contentTypeActive===null){
      //setContentTypeActive( services_items[0].type );
      ChangeContent(services_items[0], 0)
    }


    if(isSmoothScrolling===true){
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
    else{
      window.addEventListener('scroll', __OnScroll);
    }

    return ()=>{

      window.removeEventListener('scroll', __OnScroll);
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
  }, []);

  return <section id={section_id} className="our-services">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="our-services-content">
            <div className="left-content">
              <a id={`${section_id}-btn-learn-more`} href="#no-defined-link" onClick={(e)=>{e.preventDefault()}} className="btn-learn-more animate-bottom-top-fade-in">
                {our_services_label!==''?our_services_label:'Our Services'}
              </a>

              {__ServicesMenu()}
              

            </div>
            <div id={`${section_id}-right-content`} className="right-content animate-bottom-top-fade-in" style={{transitionDelay:'200ms'}}>
              
              {RightContent()}

            </div>
            {__ServicesMenu('mobile')}
          </div>
        </Col>
      </Row>
    </Container>
  </section>
}