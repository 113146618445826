import { useEffect, useRef, useState } from "react";

export default function VideoOptimized(details){
  

  const {
    id,
    className,
    url,
    autoPlay,
    playWhenInFocus,
    preload
  } = details;

  const {
    isSmoothScrolling,
    THEME_URL
  } = window.wordpress_data;

  //const __id = (id===undefined || id===null || id===''?`video-${Math.round(Math.random()*10000000000)}`:id);

  const video = useRef();
  const videoHolder = useRef();
  
  const [IsInFocus, SetIsInFocus] = useState(false);


  /*const __video = ()=>{
    return document.getElementById(`${__id}-video`);
  }
  const __vidHolder = ()=>{
    return document.getElementById(__id);
  }
  const __vidHolderB = ()=>{
    if(__vidHolder()===null){
      console.log('NUUUUUULLLLLLL __id:', __id);
    }
    return __vidHolder().getBoundingClientRect();
  }*/
  const __vidHolder = ()=>{return videoHolder.current;}
  const __vidHolderB = ()=>{
    return __vidHolder().getBoundingClientRect();
  }
  const __IsInFocusHorisontal = ()=>{
    const bounceVHolder = __vidHolderB();
    return (
      (bounceVHolder.left>=0 && bounceVHolder.left<=window.innerWidth)
      ||
      (bounceVHolder.right>=0 && bounceVHolder.right<=window.innerWidth)
      ||
      (bounceVHolder.left<0 && bounceVHolder.right>window.innerWidth)
    );
  }
  const ___IsInFocus = ()=>{
    const bounceVHolder = __vidHolderB();

    return ( 
    __IsInFocusHorisontal()
    &&
    (
      (
        bounceVHolder.top>=0 && bounceVHolder.top<=window.innerHeight
      )
      ||
      (
        bounceVHolder.bottom>=0 && bounceVHolder.bottom<=window.innerHeight
      )
      ||
      (
        bounceVHolder.top<0 && bounceVHolder.bottom>window.innerHeight
      )
    ));
  }
  const ___PlayWhenInFocus = ()=>{

    
    if(___IsInFocus() && !__vidHolder().classList.contains('is-playing')){
      __vidHolder().classList.add('is-playing');
      SetIsInFocus(true);
    }
    if(!___IsInFocus() && __vidHolder().classList.contains('is-playing')){
      __vidHolder().classList.remove('is-playing');
      SetIsInFocus(false);
    }
  }


  const __onWindowScroll = (e)=>{

    ___PlayWhenInFocus();


  }


  useEffect(()=>{

    if(isSmoothScrolling){
      document.getElementById('website-content').addEventListener('scroll', __onWindowScroll);
    }
    else{
      window.addEventListener('scroll', __onWindowScroll);
    }
    ___PlayWhenInFocus();

    //console.log('video with id:', document.getElementById(__id), ' is removed');
    

    return ()=>{
      
      window.removeEventListener('scroll', __onWindowScroll);
      document.getElementById('website-content').removeEventListener('scroll', __onWindowScroll);
    };
  }, []);

  //console.log('video is generating');


  /*return <div id={__id} className={`video-optimized ${className} `}>
    <video id={`${__id}-video`} autoPlay={autoPlay===true} muted loop preload={preload===undefined?'preload':preload} playsInline>
      <source src={url} type="video/mp4"/>
    </video>
  </div>;*/
  return <div ref={videoHolder} className={`video-optimized ${className} `} style={{backgroundColor:'grey'}}>
    {
      IsInFocus===true?
      <video 
      ref={video}
        onPlay={()=>{
          console.log('video play', id);
        }}
        onPause={()=>{
          console.log('video pause', id);
        }}
        poster={`${THEME_URL}assets/images/black-box.jpg`}
            autoPlay={true} 
            muted 
            loop 
        playsInline
            preload={'none'} >
            <source src={url} type="video/mp4"/>
          </video>
      :
      null
    }
  </div>;
}