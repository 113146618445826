import { useEffect, useState } from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import { MOBILE_WIDTH } from '../../../help/constants';
import VideoOptimized from '../video-optimized/Index';
import { getCookie, isPreloader } from '../../../help/help';

export default function Hero({data}){


  const [animate_to_the_end, setAnimateToTheEnd] = useState(false);



  const {
    section_id,
    hero_paragraph,
    learn_more_link,
    lern_more_wordpress__page_or_post__link,
    hero_media_type,
    hero_video,
    hero_photo,
    type,
    colors_type,
    device_visibility
  } = data;
  const {
    title,
    //isPreloader(),
    isAnimated,
    THEME_URL
  } = window.wordpress_data;


  //console.log('hero data:', data);

  const __renderMedia = ()=>{
    if(hero_media_type==='video'){
      return <VideoOptimized id={`${section_id}-hero-media`} autoPlay={true} muted loop className="media" url={hero_video} />;
    }
    return <img src={hero_photo} alt={title} className="media" />;
  }



  /*const _hero_content = ()=>{
  }*/


  const ___ScrollY = ()=>{
  }

  useEffect(()=>{

    if(isPreloader()===true && isAnimated){
      setTimeout(()=>{
        setAnimateToTheEnd(true);
      }, 2500);
    }
    else if(isAnimated===true){
      setAnimateToTheEnd(true);
    }

    window.addEventListener('scroll', ___ScrollY);

    return ()=>{
      window.removeEventListener('scroll', ___ScrollY);
    }
  }, [animate_to_the_end]);

  
  if(device_visibility==='mobile' && window.innerWidth>MOBILE_WIDTH){return null;}
  if(device_visibility==='desktop' && window.innerWidth<=MOBILE_WIDTH){return null;}

  return <section id={section_id} className={`hero ${type==='hero-branding'?'hero-title':''} ${type} ${colors_type}  ${animate_to_the_end===true?'animate-to-the-end':''}`}>

    

    <Container>
      <Row>
        <Col lg={12}>
          <div className="hero-content">
            <div className="hero-title">
              <img src={`${THEME_URL}assets/images/hero-title.svg`} alt={title} />
            </div>
            <div className="hero-media">
              {__renderMedia()}
            </div>
            <div className="hero-bottom-content">
              <p className="hero-paragraph" dangerouslySetInnerHTML={{ __html: hero_paragraph }} />
              <a href={learn_more_link!==''?learn_more_link:lern_more_wordpress__page_or_post__link} className="btn-learn-more">Learn More</a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
}