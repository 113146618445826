import {Button} from 'react-bootstrap';

export default function ButtonJoinUsAndDonate({color, size}){

  const {options} = window.wordpress_data;
  const {translations} = options;
  const {donate_button_text} = translations;
  console.log('donate_button_text:', donate_button_text)

  return <Button variant={`${color!==undefined && color!==false && color!=='btn-default-style'?color:'outline-secondary'}`} size={size!==undefined && size!==false?size:''} className={`btn-donate`} dangerouslySetInnerHTML={{ __html: donate_button_text }} />
}