import { useEffect, useState } from "react";

export default function Scrollinglabel({data}){

  const {
    section_id,
    text
  } = data;

  const {
    isSmoothScrolling
  } = window.wordpress_data;

  const [left_position, set_left_position] = useState('0px');

  let indexIntervalMoving = false, leftXTransform=0;
  const TimeDeltaInterval = 2000;

  const __OnScroll = (e) => {

    /*const _details = ZScroll.getDataFromE(e);
    const _target = _details._target;
    const _visibleHeight = _details._visibleHeight;
    const _contentScrollY = _details._contentScrollY;


    var section = document.getElementById(section_id);
    var div = document.getElementById(`${section_id}-div`);
    var percent = 1-(section.offsetTop - _contentScrollY)/(_visibleHeight-div.clientHeight);
    //console.log('percent:', percent, section.offsetTop)
    const left_position_new = percent * (section.clientWidth-div.clientWidth)
    set_left_position( `${left_position_new}px` );*/

    if(isInFocus() && indexIntervalMoving===false){
      indexIntervalMoving = setInterval(__MoveTheLabel, TimeDeltaInterval);
      __MoveTheLabel();
    }
    if(!isInFocus() && indexIntervalMoving!==false){
      clearInterval(indexIntervalMoving);
      indexIntervalMoving = false;
    }
    //console.log('indexIntervalMoving:', indexIntervalMoving, isInFocus());

  }

  const XDelta = ()=>{
    return (bLabel().width/20);
  }

  const __MoveTheLabel = ()=>{

    console.log('Scrolling Label Interval works...');

    leftXTransform-=XDelta();
    const label = document.getElementById(`${section_id}-div`);
    if(leftXTransform+bLabel().width<0-XDelta()){
      leftXTransform = window.innerWidth+XDelta();
      label.style.transition=`all 0s`;
      label.style.transform=`translateX(${window.innerWidth}px)`;
    }
    else{
      label.style.transform=`translateX(${leftXTransform}px)`;
      label.style.transition=`all ${TimeDeltaInterval/1000}s linear`;
    }
  }
  const bLabel = ()=>{
    const label = document.getElementById(`${section_id}-div`);
    const lBounding = label.getBoundingClientRect();
    return lBounding;
  }
  const isInFocus = ()=>{
    const section = document.getElementById(section_id);
    const sBounding = section.getBoundingClientRect();
    return (
      1===2
      || (sBounding.top>=0 && sBounding.top<=window.innerHeight) 
      || (sBounding.bottom>=0 && sBounding.bottom<=window.innerHeight)
      || (sBounding.top<0 && sBounding.bottom>window.innerHeight)
      )
  }

  
  useEffect(()=>{
    if(isSmoothScrolling){
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
    else{
      window.addEventListener('scroll', __OnScroll);
    }

    return ()=>{
      document.getElementById('website-content').removeEventListener('scroll', __OnScroll);
      window.removeEventListener('scroll', __OnScroll);
    }
  }, []);

  return <section id={section_id} className="scrolling-label">
    <div id={`${section_id}-div`} style={{left: left_position}}>{text}</div>
  </section>
}