import { useEffect, useRef } from "react";
import VideoOptimized from "../video-optimized/Index";
import { MOBILE_WIDTH } from "../../../help/constants";
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Slidergallerymedia({data}){
  
  ////console.log('data:', data);


  //return null;

  const swiper = useRef();

  const {
    section_id,
    media_items
  } = data;

  const {
    post,
    isSmoothScrolling
  } = window.wordpress_data;

  const swiperId = () => {
    return `${section_id}-swiper`;
  }

  const mediaContent = (mediaDetails, index_media)=>{
    if(mediaDetails.type==='photo'){
      return <img src={mediaDetails.photo} alt={post.post_title} />
    }
    else if(mediaDetails.type==='video'){
      return <VideoOptimized id={`${section_id}-media-video-${index_media}`} muted loop className="media" url={mediaDetails.video} />;
    }
    return null;
  }

  let iAmInFocus = false;

  const __OnScroll = (e) => {

    //console.log('is scrolling');

    //const _details = ZScroll.getDataFromE(e);
    /*const _target = _details._target;
    const _visibleHeight = _details._visibleHeight;
    const _contentScrollY = _details._contentScrollY;*/


    var section = document.getElementById(swiperId());
    var sBounding = section.getBoundingClientRect();
    var sHeight = sBounding.height;
    ////console.log('sBounding:', sBounding);


    const section_top = sBounding.top;
    const section_bottom = sBounding.bottom;
    ////console.log(e, _visibleHeight, section_top, _contentScrollY);

    if(section_top>=0 && section_top<=window.innerHeight){
      iAmInFocus = true;
    }
    else if(section_bottom>=0 && section_bottom<=window.innerHeight){
      iAmInFocus = true;
    }
    else if(section_top<0 && section_bottom>window.innerHeight){
      iAmInFocus = true;
    }
    else{
      iAmInFocus = false;
    }




    //const ___AllVideos = section.getElementsByTagName('video');
    //let __iv, _video;
    if(iAmInFocus===true){
      //var percent = 1-(0 - section.offsetTop + _contentScrollY + _visibleHeight)/(_visibleHeight+section.clientHeight);

      const percent = 1-(section_bottom/(window.innerHeight+sHeight));
      console.log('swiper:', swiper);
      swiper.current.swiper.setProgress(percent);
      if(section_id==='section-4'){
        //console.log('percent: ', percent);
      }
      /*for(__iv=0;__iv<___AllVideos.length;__iv++){
        _video = ___AllVideos[__iv];
        const vBounce = _video.getBoundingClientRect();
        const vBounceIsInFocus = (
          1===2
          || (vBounce.top>=0 && vBounce.top<=window.innerHeight) 
          || (vBounce.bottom>=0 && vBounce.bottom<=window.innerHeight)
          || (vBounce.top<0 && vBounce.bottom>window.innerHeight)
          );
        if(_video.paused===true && vBounceIsInFocus){
          console.log('slider gallery media play');
          _video.play();
        }
      }*/
    }
    else{
      ////console.log('___AllVideos:', ___AllVideos);
      /*for(__iv=0;__iv<___AllVideos.length;__iv++){
        _video = ___AllVideos[__iv];
        if(_video.paused!==true){
          console.log('slider gallery media pause');
          //_video.pause();
        }
      };*/
    }
  }


  
  const MOBILE_WIDTH_plus1 = MOBILE_WIDTH + 1;
  const breakpoints = {};
  breakpoints['0'] = {
    slidesPerView: 2,
    spaceBetween: 0,
  };
  breakpoints[MOBILE_WIDTH_plus1] = {
    slidesPerView: 5,
    spaceBetween: 0,
  };


  //let swiper;

  useEffect(()=>{

    if(isSmoothScrolling===true){
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
    else{
      window.addEventListener('scroll', __OnScroll);
    }

    /*swiper = new Swiper(`#${swiperId()}`, {
      slidesPerView: 5,
      spaceBetween: 0,
      freeMode: true,
      loop: true,
      breakpoints: breakpoints
    });*/

    return ()=>{

      window.removeEventListener('scroll', __OnScroll);
      document.getElementById('website-content').addEventListener('scroll', __OnScroll);
    }
  }, []);

  return <section id={section_id} className="slider-gallery-media">

    {/*
    <div id={swiperId()} className="swiper">
      <div className="swiper-wrapper">
        {
          media_items.map((item, key)=>{
            return <div className="swiper-slide" key={`swiper-slide-key-${key}`}>
              <div className="hero-media hero-media-for-slider">{mediaContent(item, key)}</div>
            </div>
          })
        }
      </div>
    </div>  
    */}
    <Swiper 
      ref={swiper}
      id={swiperId()}
      slidesPerView="5"
      spaceBetween="0"
      freeMode={true}
      loop={true}
      breakpoints={breakpoints}
      >
    {
      media_items.map((item, key)=>{
        return <SwiperSlide key={`swiper-slide-key-${key}`}>
          <div className="hero-media hero-media-for-slider">{mediaContent(item, key)}</div>
        </SwiperSlide>
      })
    }
    </Swiper>

  </section>
}