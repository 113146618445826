import { useEffect } from 'react';
import {Row, Container, Col, Button} from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Work({data}){

  const {
    slider_label,
    categories_for_work_projects,
    
    projects,

    what_we_do_label,
    what_we_do_menu,
    what_we_do_menu_items,

    view_all_button_label,
    view_all_button_link,
  } = data;

  const {
    THEME_URL
  } = window.wordpress_data;

  const _what_we_do_menu = ()=>{
    if(what_we_do_menu_items===false)return null;
    return <ul className="what-we-do-menu">{
      what_we_do_menu_items.map((menu_item, key)=>{
        return <li key={`menu-item-${key}`}>
          <a href={menu_item.url} target="_self">{menu_item.title}</a>
        </li>
      })
    }</ul>
  }

  console.log('data:', data);

  let swiper;

  
  useEffect(()=>{

    /*console.log(document.getElementsByClassName('the-slider-swiper'))
    swiper = new Swiper(".the-slider-swiper.swiper", {
      loop: true,
      navigation: {
        prevEl: '.work .swiper-btn-prev',
        nextEl: '.work .swiper-btn-next',
      },
      breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 0
        },
        0: {
          slidesPerView: 2,
          spaceBetween: 0
        }
      },
    });*/
    return ()=>{}
  }, [])

  return <section className={`work`}>
    <Container>
      <Row>
        <Col lg={12}>
          <div className="the-slider">
            <p className="top-label">{slider_label}</p>
            <div className="the-slider-navigation">
              <div>
                <a href="#Prev" onClick={(e)=>{e.preventDefault()}} className="work-swiper-btn-nav swiper-btn-prev"></a>
                <a href="#Next" onClick={(e)=>{e.preventDefault()}} className="work-swiper-btn-nav swiper-btn-next"></a>
              </div>
            </div>
            <div className="the-slider-holder">
              <div>
                
                {/*
                <div className="the-slider-swiper swiper">
                  <div className="swiper-wrapper">
                    {
                      projects.map((project, key)=>{
                        let photo_slider = `${THEME_URL}assets/images/image-placeholder-500x500.jpg`;
                        if(project.fields.photo_for_slider!==undefined && project.fields.photo_for_slider!==false)
                          photo_slider = project.fields.photo_for_slider;
                        else if(project.fields.photo_main!==undefined && project.fields.photo_main!==false)
                            photo_slider = project.fields.photo_main;
                        return <div className="swiper-slide" key={`work-swiper-slide-${key}`}>
                          <a href="#" onClick={(e)=>{e.preventDefault()}}>
                            <img src={photo_slider} alt={project.post_title} />
                          </a>
                        </div>
                      })
                    }
                  </div>
                </div>
                  */}
                <Swiper 
                  className='the-slider-swiper'
                  loop={true}
                  breakpoints={{
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 0
                    },
                    0: {
                      slidesPerView: 2,
                      spaceBetween: 0
                    }
                  }}>
                {
                  projects.map((project, key)=>{
                    let photo_slider = `${THEME_URL}assets/images/image-placeholder-500x500.jpg`;
                    if(project.fields.photo_for_slider!==undefined && project.fields.photo_for_slider!==false)
                      photo_slider = project.fields.photo_for_slider;
                    else if(project.fields.photo_main!==undefined && project.fields.photo_main!==false)
                        photo_slider = project.fields.photo_main;
                    return <SwiperSlide key={`work-swiper-slide-${key}`}>
                      <a href="#" onClick={(e)=>{e.preventDefault()}}>
                        <img src={photo_slider} alt={project.post_title} />
                      </a>
                    </SwiperSlide>
                  })
                }
                </Swiper>

              </div>
            </div>
            <div className="the-slider-buttons">
              <Button variant="outline-dark" className="work-view-all-products" target="_self" href={view_all_button_link}>
                <i className="circles" /> {view_all_button_label}
              </Button>
            </div>
          </div>
          <div className="what-we-do-section">
            <p className="top-label">{what_we_do_label}</p>
            {_what_we_do_menu()}
          </div>
        </Col>
      </Row>
    </Container>
  </section>
}